var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "animate-onscroll",
          rawName: "v-animate-onscroll",
          value: _vm.animate
            ? "animate__animated animate__slideUpAndAppear animate__fast"
            : "",
          expression:
            "animate ? 'animate__animated animate__slideUpAndAppear animate__fast' : ''",
        },
      ],
      staticClass: "dx-jumbotron ",
      class: _vm.classes,
    },
    [
      _vm.contentElement.alignment !== "inline"
        ? _c(
            "div",
            {
              class: {
                "text-left md:text-center":
                  _vm.contentElement.alignment === "centered",
                "text-left": _vm.contentElement.alignment === "left",
              },
            },
            [
              _vm.contentElement.header && _vm.contentElement.header.length
                ? _c(
                    "h1",
                    {
                      staticClass:
                        "text-5xl sm:text-5xl md:text-6xl lg:text-7xl tracking-tight font-bold text-black-500 hyphens",
                    },
                    [
                      _c("span", { staticClass: "block xl:inline" }, [
                        _vm._v(_vm._s(_vm.contentElement.header)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contentElement.subHeader &&
              _vm.contentElement.subHeader.length
                ? _c(
                    "h3",
                    {
                      staticClass:
                        "text-xl tracking-tight font-bold text-black-500 sm:text-2xl md:text-3xl mt-4 mb-2",
                    },
                    [
                      _c("span", { staticClass: "block xl:inline" }, [
                        _vm._v(_vm._s(_vm.contentElement.subHeader)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contentElement.bodytext && _vm.contentElement.bodytext.length
                ? _c(
                    "p",
                    {
                      staticClass:
                        "mt-3 mx-auto text-lg text-black-500 sm:text-lg md:mt-5 md:text-xl break-words",
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.contentElement.bodytext),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("dx-icons", { attrs: { icons: _vm.contentElement.icons } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contentElement.alignment === "inline"
        ? _c("div", [
            _c("div", { staticClass: "lg:grid lg:grid-cols-12 lg:gap-8" }, [
              _vm.contentElement.header && _vm.contentElement.header.length
                ? _c("div", { staticClass: "text-left lg:col-span-6" }, [
                    _c("h1", { staticClass: "w-full md:w-11/12" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "mt-1 block text-4xl tracking-tight font-bold sm:text-5xl xl:text-6xl",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.contentElement.header) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.contentElement.header && _vm.contentElement.header.length
                ? _c("div", {
                    staticClass:
                      "hidden mt-12 lg:mt-0 relative mt-0 max-w-none mx-0 lg:col-span-6 lg:flex",
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "hidden mt-12 lg:mt-0 relative mt-0 max-w-none mx-0 lg:col-span-6 lg:flex",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "mt-12 lg:mt-0 relative mt-0 max-w-none mx-0 lg:col-span-6 lg:flex",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mt-3 text-base text-black-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl",
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.contentElement.bodytext),
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }