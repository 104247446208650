var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.icons.length
    ? _c(
        "div",
        {
          staticClass:
            "dx-icons flex flex-wrap flex-row justify-center lg:justify-between w-full",
          class: _vm.classes,
        },
        _vm._l(_vm.icons, function (icon, key) {
          return icon.link
            ? _c(
                "dx-link-wrap",
                {
                  key: key,
                  staticClass: "dx-icons__link text-normal uppercase",
                  attrs: {
                    centered: false,
                    "is-button": false,
                    link: icon.link,
                    rounded: false,
                    variant: "black",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col items-center mb-2 text-center",
                      staticStyle: { width: "10rem" },
                    },
                    [
                      icon.icon
                        ? _c("dx-image", {
                            staticClass: "w-36 dx-jumbotron-icon__img mb-3",
                            attrs: { image: icon.icon, definition: "lg" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !icon.link
                        ? _c("b", {
                            staticClass:
                              "font-semibold text-normal uppercase tracking-widest hyphens",
                            domProps: { innerHTML: _vm._s(icon.label) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "tracking-widest hyphens font-semibold mb-3",
                        domProps: { innerHTML: _vm._s(icon.label) },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e()
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }