








































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxIcons from "../../../../../Component/Common/Icons/Icons.vue";

@Component({
    name: 'dx-jumbotron',
    components: {DxIcons}
})
export default class Jumbotron extends Vue
{
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;

    _contentElement: any;

    created()
    {
        // console.log('[Jumbotron] _contentElement' , this.context.data.getAll());
        this._contentElement = this.context.data.getAll();
    };

    get contentElement()
    {
        return this._contentElement
    }

    get classes()
    {
        let classes = '';
        if (this._contentElement.spacer)
        {
            classes += this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.background)
        {
            classes += ' ' + this._contentElement.background;
        }

        if (this._contentElement.alignment === 'inline')
        {
            classes += ' dx--container-wide';
        }
        else{
            classes += ' dx--container';
        }

        return classes;
    }
};
