

















import {Component, Prop, Vue} from 'vue-property-decorator';
import DxImage from '../Assets/Image/Image.vue';
import DxLinkWrap from '../LinkWrap/LinkWrap.vue';
import DxIcon from '../Icon/Icon.vue';

@Component({
    name: 'dx-icons',
    components: {DxLinkWrap, DxImage, DxIcon}
})
export default class Icons extends Vue {
    @Prop() icons: [];
    @Prop({default: 'mt-12 md:mt-24'}) classes: string;

    mounted() {
        // console.log('[Icons]', this.icons)

    }

};
